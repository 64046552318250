import { Box, Button, CircularProgress, Container, FormControl, Grid, MenuItem, Modal, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, {useState, useEffect, version} from 'react';
import '../../saas/screens/VersionController.scss';
import axios from "axios";
import { API } from "../../utils/Api";
import { Form, Formik } from 'formik';
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import Chip, { chipClasses } from '@mui/material/Chip';
// import { Search } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    borderRadius: '12px'
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
}));

const VersionController = () => {

    const token = localStorage.getItem("token");
    const [processingFlag, setProcessingFlag] = useState(false);
    const [AddBtnLoading, setAddBtnLoading] = useState(false);
    const [editBtnLoading, setEditBtnLoading] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [versionsData, setVersionsData] = useState();
    const [versions, setVersions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [versionForm, setVersionForm] = useState({
        version: "",
        os_type: "",
        description: ""
    });

    const validationSchema = Yup.object({
        version: Yup.string()
            .required("Version Number is required")
            .trim(),
        os_type: Yup.string()
            .required("Os Type is required"),
        description: Yup.string()
            .required("Description is required")
            .trim()
            .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Description can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
            .min(6, 'Description must be at least 6 characters long'),
    })

    const editValidationSchema = Yup.object({
        version_number: Yup.string()
            .required("Version Number is required")
            .trim(),
        os_type: Yup.string()
            .required("Os Type is required"),
        description: Yup.string()
            .required("Description is required")
            .trim()
            .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Description can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
            .min(6, 'Description must be at least 6 characters long'),
    })

    const columns = [
        { id: "sr.no", label: "Sr.No" },
        { id: "version", label : "Version Number", minWidth: 140 },
        { id: "Os Type", label : "Os Type" },
        { id: 'descrption', label: "Description" },
        { id: 'status', label: "Status" },
        {
            id: "action",
            label: "Actions",
            // minWidth: 100,
            align: "left",
        }
    ]

    const initialValues = {
        version_number: versionsData?.version_number || 'Loading....',
        os_type: versionsData?.os_type || 'Loading....',
        description: versionsData?.description || 'Loading....'
    }

    // Function to remove HTML tags
    function stripHtmlTags(str) {
        return str.replace(/<[^>]*>/g, '');
    }

    const getVersions = async() => {
        setProcessingFlag(true);
        try {
            const config = {
                headers : {
                  Authorization : `${token}`
                }
            };

            const response = await axios.get(`${API.BASE_URL}app-version/list`, config);
            if(response.data.status === true) {
                console.log("organization Data:", response?.data?.data?.list);
                setProcessingFlag(false);
                setVersions(response?.data?.data?.list);
              } else {
                toast.error(response.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                }) 
              }
        } catch (error) {
            let errorMessage = "An error occurred while fetching client's products.";
            if (error.response && error.response.data) {
              errorMessage = error.response.data.message;
            }
            setProcessingFlag(false);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
          });
        } finally {
            setProcessingFlag(false);
        }
    }

    const handleSubmitAddVersion = async(e) => {
        setAddBtnLoading(true);
        let versionData = {
            version: e.version,
            os_type: e.os_type,
            description: e.description
        }
        console.log("version data: ", versionData);
        try {
            const config = {
                headers : {
                  Authorization : `${token}`
                }
            };

            const response = await axios.post(`${API.BASE_URL}app-version/add`, versionData, config );
            console.log("response :", response);
            setVersionForm({
                version: "",
                os_type: "",
                description: ""
            })
            getVersions();
            toast.success("New app version added successfully");
        } catch (error) {
            console.log(error);
            let errorMessage = '';
                if (
                  error.response?.status === 406
                ) {
                  errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (error.response?.status === 400) {
                    errorMessage = error?.response?.data?.data?.form_error
                }
                else if (
                  error.response?.status === 412
                ) {
                  errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                  errorMessage = error?.response?.data?.error
                }

                errorMessage = stripHtmlTags(errorMessage)
        
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
        } finally {
            setAddBtnLoading(false);
        }
    }

    const handleOpen = (versionData) => {
        setOpenEditModal(true);
        console.log("hello versions", versionData);
        setVersionsData(versionData)
    }

    const handleSubmitEditVersion = async(values) => {
        console.log("click");
        setEditBtnLoading(true);
        let updatedData = {
            id: versionsData.id,
            version: values.version_number,
            os_type: values.os_type,
            description: values.description
        }
        try {
            const config = {
                headers : {
                    Authorization : `${token}`
                }
            }
            const response = await axios.put(`${API.BASE_URL}app-version/update`, updatedData, config);
            getVersions();
            setOpenEditModal(false);
            toast.success("Version Updated Successfully");
        } catch (error) {
            console.log(error);
            let errorMessage = '';
                if (
                  error.response?.status === 406
                ) {
                  errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (error.response?.status === 400) {
                    errorMessage = error?.response?.data?.data?.form_error
                }
                else if (
                  error.response?.status === 412
                ) {
                  errorMessage = error?.response?.data?.data?.message;
                } else if (error.response?.status === 500) {
                  errorMessage = error?.response?.data?.error
                }

                errorMessage = stripHtmlTags(errorMessage)
        
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
                });
        } finally {
            setEditBtnLoading(false);
        }
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    }

    const handleDeleteClick = (versionId) => {
        console.log("Version Id:", versionId);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${API.BASE_URL}app-version/delete?id=${versionId}`, {
                        headers: {
                        Authorization: `${token}`,
                    },});
                    if (response.status === 200) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Version has been deleted.",
                            icon: "success"
                        });
                        getVersions();
                        // Handle any further actions after successful deletion, such as updating UI or state
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: "Failed to delete Version.",
                            icon: "error"
                        });
                    }
                } catch (error) {
                    console.error("Error deleting user:", error);
                    Swal.fire({
                        title: "Error!",
                        text: "Failed to delete Version.",
                        icon: "error"
                    });
                    // Handle errors, such as displaying an error message to the user
                }
            }
        });
    }

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        console.log("searching :", searchTerm);
        setSearchTerm(searchTerm);
        if (searchTerm === '') {
            getVersions();
        } else {
            const searchedData = versions.filter( data => data.version_number.toLowerCase().includes(searchTerm) || 
            data.os_type.toLowerCase().includes(searchTerm) );
            setVersions(searchedData);
        }
    }

    const getStatusChip = (status) => {
        return status === 'OPEN' ? (
            <Chip label="Active" style={{ backgroundColor: 'green', color: 'white' }} />
        ) : (
            <Chip label="Inactive" style={{ backgroundColor: 'red', color: 'white' }} />
        )
    }

    useEffect(()=> {
        getVersions();
    }, [])

  return (
    // <Container maxWidth="sm">
    <div className='version-control-main'>
        <Grid container spacing={2} p={1.5}>

            {/* <------------------ Add Version Form ------------------> */}
            <Grid item xs={4}>
                <Box className="add-version-control-form">
                    <div className='add-version-header'>
                        <Typography className="add-version-text">
                            ADD VERSION
                        </Typography>
                    </div>
                    <Container>
                        <Formik initialValues={versionForm} onSubmit={handleSubmitAddVersion} validationSchema={validationSchema} enableReinitialize={true}>
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                <Form style={{ marginTop: '20px' }}>
                                    <TextField
                                        label="Version Number"
                                        id="outlined-size-small"
                                        size="small"
                                        type="text"
                                        className="addRiderformText"
                                        name="version"
                                        value={values.version}
                                        onBlur={handleBlur('version')}
                                        // onChange={handleChange('version')}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            if (/^[0-9.]*$/.test(value)) {
                                                handleChange(event);
                                            }
                                        }}
                                        required
                                        fullWidth
                                    />
                                    <div style={{marginTop: '-10px'}}>
                                        {
                                            errors.version ?
                                                <div>
                                                    {
                                                        touched.version ?
                                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.version}</Typography> : <></>
                                                    }
                                                </div> :
                                                <div></div>
                                        }
                                    </div>
                                    <FormControl sx={{ width: '100%', marginTop: '20px' }}>
                                        <Select
                                            size="small"
                                            displayEmpty
                                            required
                                            input={<OutlinedInput />}
                                            name="os_type"
                                            placeholder='Select'
                                            onBlur={handleBlur}
                                            onChange={handleChange('os_type')}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                  return <Typography style={{ color: 'gray' }}>Select OS Type</Typography>;
                                                }
                                                return selected;
                                            }}
                                        >
                                            {/* <MenuItem value="" disabled>
                                                <Typography style={{ color: 'gray' }}>Select</Typography>
                                            </MenuItem> */}
                                            <MenuItem value="ANDROID">Android</MenuItem>
                                            <MenuItem value="IOS">iOS</MenuItem>
                                        </Select>
                                        <div style={{marginTop: '-10px'}}>
                                            {
                                                errors.os_type ?
                                                    <div>
                                                        {
                                                            touched.os_type ?
                                                                <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.os_type}</Typography> : <></>
                                                        }
                                                    </div> :
                                                    <div></div>
                                            }
                                        </div>
                                    </FormControl>
                                    <TextField
                                        label="Description"
                                        id="outlined-size-small"
                                        size="small"
                                        className="addRiderformText"
                                        sx={{ marginTop: '30px' }}
                                        name="description"
                                        multiline
                                        rows={3}
                                        onChange={handleChange('description')}
                                        onBlur={handleBlur('description')}
                                        value={values.description}
                                        required
                                        fullWidth
                                    />
                                    <div style={{marginTop: '-10px'}}>
                                            {
                                                errors.description ?
                                                    <div>
                                                        {
                                                            touched.description ?
                                                                <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.description}</Typography> : <></>
                                                        }
                                                    </div> :
                                                    <div></div>
                                            }
                                    </div>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{width: '100px', borderRadius: '20px', backgroundColor: '#1E9CED', marginTop: '15px' , marginBottom: '10px'}}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                            {AddBtnLoading && (
                                            <CircularProgress
                                                className="loader"
                                                size={24}
                                                style={{ marginLeft: AddBtnLoading ? 8 : 0 }}
                                            />
                                            )}
                                            <span style={{ visibility: AddBtnLoading ? "hidden" : "visible" }}>Submit</span>
                                        </div>
                                        
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Box>
            </Grid>

            {/* <------------------ List Of Versions -----------------> */}
            <Grid item xs={8}>
                <Paper className="version-list-table">
                    <div className='version-list-table-header'>
                        <Typography className="text-left organizationText" sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
                            List Of Versions
                        </Typography>
                        <Search className='version-list-search' >
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={handleSearch}
                            />
                        </Search>
                    </div>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    { columns.map((column) => (
                                        <TableCell 
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, fontWeight: 700 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ) )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    { 
                                    // processingFlag ? (
                                    //     <CircularProgress className="tableLoader" />
                                    // ) : (
                                        versions.map((version, ind) => (
                                            <TableRow>
                                                <TableCell>{ind +1}</TableCell>
                                                <TableCell>{version?.version_number}</TableCell>
                                                <TableCell>{version?.os_type}</TableCell>
                                                <TableCell>{version?.description}</TableCell>
                                                <TableCell>{getStatusChip(version?.status)}</TableCell>
                                                <TableCell>
                                                <span className="d-flex" style={{fontSize: '25px', alignItems: 'center'}}>
                                                    <EditIcon aria-label="edit" color="primary" style={{ cursor: 'pointer', marginRight: '5px' }} onClick={ () => handleOpen(version)} />
                                                    <DeleteOutlinedIcon style={{ color: 'red', cursor: 'pointer', marginLeft: '5px' }} onClick={ () => handleDeleteClick(version.id)} />
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ) )
                                    // )
                                    }
                            </TableBody>
                        </Table>
                        { processingFlag && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress />
                            </div>
                            ) }

                            { processingFlag || versions?.length === 0 && (
                            <Typography className='text-center noRecordsFound'>No Records Found</Typography>
                        ) }
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>

        {/* <-------------------- Edit Version Modal -----------------> */}
        <Modal
            open = {openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            
            <Formik initialValues={initialValues} onSubmit={handleSubmitEditVersion} validationSchema={editValidationSchema} enableReinitialize={true} >
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (        
                    <Box sx={style}>
                        <div className="d-flex update-version-header">
                            <Typography className="addRiderText">Update Rider Details</Typography>
                            <CloseIcon className='update-version-close-icon' onClick={handleCloseEditModal} />
                        </div>
                        <Form>
                            <TextField
                                label="Version Number"
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                className="update-version-form-text-field"
                                name="version_number"
                                value={values?.version_number}
                                onBlur={handleBlur('version_number')}
                                // onChange={handleChange('version')}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^[0-9.]*$/.test(value)) {
                                        handleChange(event);
                                    }
                                }}
                                required
                                fullWidth
                            />
                            <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.version_number ?
                                            <div>
                                                {
                                                    touched.version_number ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.version_number}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                            </div>
                            <FormControl sx={{ width: '100%' }} className="update-version-form-text-field">
                                <Select
                                    size="small"
                                    displayEmpty
                                    required
                                    label="Select"
                                    input={<OutlinedInput />}
                                    // name="platform"
                                    name="os_type"
                                    value={values?.os_type}
                                    onChange={handleChange('os_type')}
                                    onBlur={handleBlur('os_type')}
                                >
                                    <MenuItem value="ANDROID">Android</MenuItem>
                                    <MenuItem value="IOS">IOS</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.os_type ?
                                            <div>
                                                {
                                                    touched.os_type ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.os_type}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                            </div>
                            <TextField
                                label="Description"
                                id="outlined-size-small"
                                size="small"
                                // className="addRiderformText"
                                className="update-version-form-text-field"
                                name="description"
                                multiline
                                rows={3}
                                value={values?.description}
                                onChange={handleChange('description')}
                                onBlur={handleBlur('description')}
                                required
                                fullWidth
                            />
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                style={{width: '100px', borderRadius: '20px', backgroundColor: '#1E9CED', marginTop: '15px' }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                            {editBtnLoading && (
                                            <CircularProgress
                                                className="loader"
                                                size={24}
                                                style={{ marginLeft: editBtnLoading ? 8 : 0 }}
                                            />
                                            )}
                                            <span style={{ visibility: editBtnLoading ? "hidden" : "visible" }}>Update</span>
                                </div>
                            </Button>
                        </Form>
                    </Box>
                )}
            </Formik>
        </Modal>

    </div>
    // </Container>
  )
}

export default VersionController