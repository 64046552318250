import React from "react";
import Sidebar from './components/Sidebar';
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const AppLayout = () => {
    return (
        <div style={{ display: 'flex' }}>
            <Box>
                <Sidebar />
            </Box>
            <Box component="main" className="mainBox full-height" sx={{ flexGrow: 2, backgroundColor: '#D2E3DD' }}>
                {<Outlet />}
            </Box>
        </div>
    )
}

export default AppLayout
