import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./screens/App/Dashboard";
import RiderManagement from "./screens/App/RiderManagement";
import AppLayout from "./AppLayout";
import LogIn from "./screens/Auth/Login";
import OrganizationManagement from "./screens/App/OrganizationManagement";
import VersionController from "./screens/App/VersionController";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="" element={<AppLayout/>}>
                <Route path="/ridermanagement" element={<RiderManagement />} />
                <Route path="/organization" element={<OrganizationManagement />}/>
                <Route path="/versioncontrol" element={<VersionController />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes