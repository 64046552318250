import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Images from '../utils/Images';
import { Button, Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Swal from "sweetalert2";
import '../saas/components/Navbar.scss';
import { useLocation } from 'react-router-dom';
import '../saas/components/SideBar.scss';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import InventoryIcon from '@mui/icons-material/Inventory';
import TuneIcon from '@mui/icons-material/Tune';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const navItems = [
  {
    text: "Rider Management",
    icon: <TwoWheelerIcon />
  },
  {
    text: "Organization",
    icon: <InventoryIcon />
  },
  {
    text: "Version Control",
    icon: <TuneIcon />
  }
]

function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuData, setMenuData] = useState("Dashboard");
  const navigate = useNavigate()
  const clientEmail = localStorage.getItem("clientEmail");
  const location = useLocation();

  const handleDrawerToggle = () => {
    setOpen(open);
  };

  const handleLogout = () => {
    // localStorage.clear();
    // navigate('/')
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be logged out!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!'
    }).then((result) => {
        if (result.isConfirmed) {
            localStorage.clear();
            navigate('/');
        }
    })

  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Hidden mdUp>
        <AppBar position="fixed" className="navbar">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="d-flex">
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start" >
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
            </div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }} sx="true" nowrap="true" component="div">
                <div className="text-center">
                  {/* <img src={Images.bexLogo} className="navLogoMob" alt='img' /> */}
                  <p className="navHeaderMob text-center" style={{ fontWeight:'bold', fontSize:'20px' }}>ZUPZAP ADMIN PANEL</p>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ alignContent: 'center' }}>
              <div className="d-flex">
                <div>
                  <img className="bell-iconMob" src={Images.bellIconMob} alt='bell-icon' />
                </div>
                <div className="">
                  <img className="profileSmallMob" src={Images.profileSmallWhite} alt='profile-icon' />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdDown>
        <AppBar position="fixed" className="navbar">
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="d-flex">
              {/* <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start" >
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton> */}
              <div style={{ display: 'flex', alignItems: 'center' }} sx="true" nowrap="true" component="div">
                <div>
                  {/* <img src={Images.bexLogo} className="navLogo" alt='img' /> */}
                </div>
                <h2 className="navHeader">ZUPZAP ADMIN PANEL</h2>
              </div>
            </div>
            <div className="d-flex" style={{ alignContent: 'center' }}>
              <div className="d-flex">
                {/* <div>
                  <img className="bell-icon" src={Images.bellIcon} alt='bell-icon' />
                </div> */}
                <div className="profileBox d-flex" style={{top: '0'}}>
                  <img className="profileSmall" src={Images.profileSmall} alt='profile-img' />
                  <p className="email">{clientEmail}</p>
                </div>
                <div>
                  <Button variant='filled' onClick={handleLogout} sx={{
                    background: 'white',
                    borderRadius: '18px',
                    // top: '2px',
                    color: 'black',
                    "&:hover": {
                      background: "white",
                      color: 'black'
                    },
                  }}>Logout</Button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List className="sideBar">
          {
            navItems.map(({ text, icon }) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => setMenuData(text)}
              >
                <ListItemButton
                  onClick={() => {
                    navigate(`/${text.replace(/\s+/g, '').toLowerCase()}`);
                  }}
                  sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                  // className={menuData === text ? 'active-item' : 'inactive-item'}
                  className={location.pathname === `/${text.replace(/\s+/g, '').toLowerCase()}` ? 'active-item' : 'inactive-item'}
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{ opacity: open ? 1 : 0 }}
                    className={menuData === text ? 'active-item-text' : 'inactive-item-text'}
                  />
                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Drawer>
    </Box>
  );
}

export default Sidebar;