import { Box, TextField, Typography, Button, IconButton, Modal, CircularProgress, Grid, InputAdornment, TableFooter, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Swal from "sweetalert2";
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { API } from "../../utils/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { Password } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
// import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    borderRadius: '12px'
};

const columns = [
  { id: "Sr.No", label: "Sr.No" },
  { id: "organization_name", label: "Org Name", 
    // minWidth: 140 
  },
  { id: "name", label: "Name", 
    // minWidth: 120
  },
  {
    id: "mobile",
    label: "Mobile",
    // minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    // minWidth: 120,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "address",
    label: "Address",
    minWidth: 160,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "pincode",
    label: "Pincode",
    // minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "city",
    label: "City",
    // minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "state",
    label: "State",
    // minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "actions",
    label: "Action",
    // minWidth: 90,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size, actions) {
  const density = population / size;
  return { name, code, population, size, density, actions };
}

function RiderManagement() {
    const [page, setPage] = React.useState(0);
    const token = localStorage.getItem("token");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userData, setUserdata] = useState()
    const [open, setOpen] = useState(false);
    const handleOpen = (user) => {
      setOpen(true)
      console.log(user,"rehbruse");
      setUserdata(user)
    };
    const handleClose = () => setOpen(false);
    const [riders, setRiders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [organization, setOrganization] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [ updateOrganisationName, setUpdateOrganisationName] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)

    const [formData, setFormData] = useState({
      name: '',
      mobile: '',
      email: '',
      address: '',
      pincode: '',
      city: '',
      state: '',
      password: '',
      confirmPassword: ''
    });

    const initialValues = ({
      name: userData?.name || 'Loading...',
      mobile: userData?.mobile || 'Loading...',
      email: userData?.email || 'Loading...',
      address: userData?.address || 'Loading...',
      pincode: userData?.pincode || 'Loading...',
      city: userData?.city || 'Loading...',
      state: userData?.state || 'Loading...',
    })

    const validationSchema = Yup.object({
      name: Yup.string()
        .required("Name is required")
        .trim() // Remove leading and trailing whitespace
        .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Name can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
        .min(2, 'Name must be at least 2 characters long'),
      mobile: Yup.string()
        .required("Mobile number is required")
        .min(10, "Mobile number must be at least 10 characters")
        .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
      email: Yup.string()
        .required("Email is required")
        .trim() // Remove leading and trailing whitespace
        .email('Enter a valid email address'), // Validate email format
      address: Yup.string()
        .required('Address is required!')
        .trim()
        .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Address can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
        .min(2, 'Address must be at least 2 characters long'),
      pincode: Yup.string()
        .required('Pincode is required')
        .trim()
        .matches(/^[0-9]+$/, 'Pincode must contain only numeric values')
        .matches(/^\d{6}$/, 'Invalid pinocde or it does not exists'),
      city: Yup.string()
          .required('City is required')
          .trim()
          .min(2, 'City must be at least 2 characters long')
          .matches(/^[A-Za-z\s-]+$/, 'City must not contain special characters'),
      state: Yup.string()
          .required('State is required!')
          .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
          .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]')
          .min(2, 'State must be at least 2 characters long'),
      password: Yup.string()
          .required('New password is required')
          .trim()
          .min(8, 'New password must be at least 8 characters long'),
      confirmPassword: Yup.string()
          .required('Confirm password is required')
          .trim()
          .min(8, 'New password must be at least 8 characters long'),
    })

    const validationUpdateSchema = Yup.object({
      name: Yup.string()
        .required("Name is required")
        .trim() // Remove leading and trailing whitespace
        .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Name can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
        .min(2, 'Name must be at least 2 characters long'),
      mobile: Yup.string()
        .required("Mobile number is required")
        .min(10, "Mobile number must be at least 10 characters")
        .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
      email: Yup.string()
        .required("Email is required")
        .trim() // Remove leading and trailing whitespace
        .email('Enter a valid email address'), // Validate email format
      address: Yup.string()
        .required('Address is required!')
        .trim()
        .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Address can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
        .min(2, 'Address must be at least 2 characters long'),
      pincode: Yup.string()
        .required('Pincode is required')
        .trim()
        .matches(/^[0-9]+$/, 'Pincode must contain only numeric values')
        .matches(/^\d{6}$/, 'Invalid pinocde or it does not exists'),
      city: Yup.string()
          .required('City is required')
          .trim()
          .min(2, 'City must be at least 2 characters long')
          .matches(/^[A-Za-z\s-]+$/, 'City must not contain special characters'),
      state: Yup.string()
          .required('State is required!')
          .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
          .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]')
          .min(2, 'State must be at least 2 characters long'),  
    })

    // const [editFormData, setEditFormData] = useState({
    //   name: '',
    //   mobile: '',
    //   email: '',
    //   address: '',
    //   pincode: '',
    //   city: '',
    //   state: '',
    //   password: ''
    // })

    const handleChangeTest = (dataKey,dataValue) => {
      // const { name, value } = event.target;
      // setFormData({
      //   ...formData,
      //   [name] : value
      // });
      setFormData(prevFormData => ({
        ...prevFormData,
        [dataKey]: dataValue
      }));
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   [name]: value
      // }));
    }

    const handleSubmitRider = async (values) => {
      // event.preventDefault();
      setAddBtnLoading(true);
      let addFormData = {
        ...values, 
        organization: personName
      }
      try {
        const response = await axios.post(`${API.BASE_URL}riders/add`, addFormData,{ 
          headers: {
              Authorization: `${token}`,
          },}
        );
        setFormData({
          organization: '',
          name: '',
          mobile: '',
          email: '',
          address: '',
          city: '',
          state: '',
          pincode: '',
          password: '',
          confirmPassword: ''
        });
        setPersonName('')
        getRidersData();
        toast.success("New Rider Created Successfully!");
      } catch (error) {
        console.log("Error Adding new rider",error);
        toast.error("Failed To Create New Rider");
      } finally {
        setAddBtnLoading(false);
      }
    }

    const handleUpdateSubmit = async (values) => {
      console.log("hello", updateOrganisationName);
      setLoading2(true);
      console.log(userData,"hdgchsdg");
      let updatedData = {
        id: userData.id,
        organization: updateOrganisationName,
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pincode 
      }
      console.log(updatedData,"updated data");

      try {
        const response = await axios.put(`${API.BASE_URL}riders/update`, updatedData, { 
        headers: {
          Authorization: `${token}`,
      },}
      );
        console.log('new rider: ', response.data);
        getRidersData();
        handleClose();
        setLoading2(false);
        toast.success("Rider Updated Successfully!");
      } catch (error) {
        console.log("Error updating new rider",error);
        setLoading2(false);
        toast.error("Failed To Update Rider");
      }

    }

    const handleDeleteClick = (userId) => {
      console.log("User Id:", userId);
      Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  const response = await axios.delete(`https://devapis.zupzap.com/v1/riders/delete?id=${userId}`, {
                    headers: {
                      Authorization: `${token}`,
                  },});
                  if (response.status === 200) {
                      Swal.fire({
                          title: "Deleted!",
                          text: "Your file has been deleted.",
                          icon: "success"
                      });
                      getRidersData();
                      // Handle any further actions after successful deletion, such as updating UI or state
                  } else {
                      Swal.fire({
                          title: "Error!",
                          text: "Failed to delete user.",
                          icon: "error"
                      });
                  }
              } catch (error) {
                  console.error("Error deleting user:", error);
                  Swal.fire({
                      title: "Error!",
                      text: "Failed to delete user.",
                      icon: "error"
                  });
                  // Handle errors, such as displaying an error message to the user
              }
          }
      });
    };

    const getRidersData = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `${token}`,
                },
            };
            setLoading(true);
            const response = await axios.get(`${API.BASE_URL}riders/list`, config);
            if (response.data.status === true) {
                console.log('Riders data', response);
                setLoading(false);
                setRiders(response.data.data.list);
            } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            })
            }
        } catch (error) {
            let errorMessage = "An error occurred while fetching client's products.";
            if (error.response && error.response.data) {
            errorMessage = error.response.data.message;
            }
            setLoading(false);
            toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            });
        }
    }

    const getOrganizationData = async () => {
      try {
        const config = {
          headers : {
            Authorization : `${token}`
          }
        };
        const response = await axios.get(`${API.BASE_URL}organization/list`, config);
        if(response.data.status === true) {
          console.log("organization Data:", response.data.data.list);
          setOrganization(response.data.data.list);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          }) 
        }
      }catch (error) {
        let errorMessage = "An error occurred while fetching client's products.";
              if (error.response && error.response.data) {
              errorMessage = error.response.data.message;
              }
              toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
      }
    }

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };

    const handleSelectChange = (event) => {
      console.log("Event change", event);
      setPersonName(event.target.value); // Update state with selected values
      console.log("hello",event.target.value);
    };

    const handleUpdateSelectChange = (event) => {
      console.log("console:", event.target.value);
      setUpdateOrganisationName(event.target.value)
    }

    const handleFilterChange = async (event) => {
      setLoading(true);
      console.log("filter event:", event.target.value);
      let filterValue = event.target.value
      try {
        const response = await axios.get(`${API.BASE_URL}riders/list?sort=${filterValue}`, { 
          headers: {
            Authorization: `${token}`,
          },
        }
        );

        if (response.data.status === true) {
          console.log('Riders data', response);
          setRiders(response.data.data.list);
          setLoading(false);
        } else {
          toast.error(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
          })
        }
        console.log('new rider: ', response.data.data.list);
      } catch (error) {
        console.log("Error Adding new rider",error);
      }
    }

    const handleSearch = (event) => {
      const searchTerm = event.target.value.toLowerCase();
      console.log('searching', searchTerm);
      setSearchTerm(searchTerm);

      if (searchTerm === '') {
          // If the search bar is cleared, display all the data
          // setRiders(riders);
          getRidersData();
      } else {
          const searchedData = riders.filter(data => data.name.toLowerCase().includes(searchTerm) || 
          data.mobile.toLowerCase().includes(searchTerm) );
          console.log("Searched new data:", searchedData);
          setRiders(searchedData);
      }
    }

    const highlightSearchTerm = (text, searchTerm) => {
      if (!searchTerm || searchTerm === '') {
        return text;
      }
    
      // Split the text into parts based on the search term
      const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
      return (
        <span>
          {parts.map((part, index) => (
            <span
              key={index}
              style={
                part.toLowerCase() === searchTerm.toLowerCase()
                  ? { backgroundColor: 'yellow' }
                  : null
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    };

    const generateRandomPassword = () => {
      const length = 10; // Adjust the length of the generated password as needed
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";

      let password = "";
      for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset[randomIndex];
      }

      // setFormData({
      //     ...formData,
      //     password: password,
      //     confirmPassword: password
      // });

      setFormData((prevFormData) => ({
        ...prevFormData,
        password: password,
        confirmPassword: password
      }));

    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
      getRidersData();
      getOrganizationData();
    }, []);


  return (
    <div className="riderMain">

      {/* <---------------- Add Rider Form ---------------> */}
      <Box className="addRiderform">
          <div className="addRiderTextHeader">
            <Typography className="addRiderText">ADD RIDER</Typography>
          </div>
            
          <Container>
          <Formik initialValues={formData} onSubmit={handleSubmitRider} validationSchema={validationSchema} enableReinitialize={true}>
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
              <div>
                <Grid container spacing={3} sx={{paddingTop: '12px'}}>
                  {/* First Row */}
                  <Grid item xs={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        size="small"
                        displayEmpty
                        value={personName}
                        required
                        onChange={handleSelectChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span style={{ color: 'gray' }}>Select Organization</span>;
                          }
                          const selectedOrg = organization.find(org => org.id === selected);
                          return selectedOrg ? selectedOrg.name : '';
                        }}
                      >
                        {organization.map((organizationName) => (
                          <MenuItem
                            key={organizationName?.id}
                            value={organizationName?.id}
                          >
                            {organizationName?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      id="outlined-size-small"
                      size="small"
                      className="addRiderformText"
                      name="name"
                      value={values?.name}
                      // onChange={handleChange('name')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('name')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.name ?
                                <div>
                                    {
                                        touched.name ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.name}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Mobile"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      className="addRiderformText"
                      name="mobile"
                      value={values?.mobile}
                      // onChange={handleChange('mobile')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}

                      onBlur={handleBlur('mobile')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.mobile ?
                                <div>
                                    {
                                        touched.mobile ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.mobile}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email"
                      id="outlined-size-small"
                      size="small"
                      className="addRiderformText"
                      name="email"
                      value={values?.email}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('email')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.email ?
                                <div>
                                    {
                                        touched.email ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.email}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>

                  {/* Second Row */}
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      id="outlined-size-small"
                      size="small"
                      className="addRiderformText"
                      name="address"
                      multiline
                      rows={2} // Adjust the number of rows as needed
                      value={values?.address}
                      // onChange={handleChange('address')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('address')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.address ?
                                <div>
                                    {
                                        touched.address ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.address}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  
                  <Grid item xs={4}>
                    <TextField 
                      label="Pincode"
                      id="outlined-size-small"
                      size="small"
                      type="number"
                      className="addRiderformText"
                      name="pincode"
                      value={values?.pincode}
                      // onChange={handleChange('pincode')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('pincode')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.pincode ?
                                <div>
                                    {
                                        touched.pincode ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.pincode}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="City"
                      id="outlined-size-small"
                      size="small"
                      className="addRiderformText"
                      name="city"
                      value={values?.city}
                      // onChange={handleChange('city')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('city')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.city ?
                                <div>
                                    {
                                        touched.city ?
                                            <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.city}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>

                  {/* Third Row */}
                  <Grid item xs={4}>
                    <TextField
                      label="State"
                      id="outlined-size-small"
                      size="small"
                      className="addRiderformText"
                      name="state"
                      value={values?.state}
                      // onChange={handleChange('state')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('state')}
                      required
                      fullWidth
                    />
                    <div style={{marginTop: '-10px'}}>
                        {
                            errors.state ?
                                <div>
                                    {
                                        touched.state ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.state}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  
                  <Grid item xs={6} sx={{paddingTop: '10px !important'}}>
                    <Typography onClick={generateRandomPassword} sx={{fontSize: '10px', marginBottom: '2px', cursor: 'pointer', textDecoration: 'underline', color: 'blue', textAlign: 'right'}} >Generate Password</Typography>
                    <TextField
                      id="outlined-password-input"
                      label="Password"
                      // type="password"
                      type={showPassword ? 'text' : 'password'}
                      size="small"
                      className="addRiderformText"
                      name="password"
                      value={values?.password}
                      // onChange={handleChange('password')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('password')}
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                      }}
                    />
                    <div style={{marginTop: '-5px'}}>
                        {
                            errors.password ?
                                <div>
                                    {
                                        touched.password ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.password}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: '27px !important' }}>
                    <TextField
                      id="outlined-password-input"
                      label="Confirm Password"
                      // type="password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      size="small"
                      className="addRiderformText"
                      name="confirmPassword"
                      value={values?.confirmPassword}
                      // onChange={handleChange('confirmPassword')}
                      onChange={(event)=> handleChangeTest(event.target.name, event.target.value)}
                      onBlur={handleBlur('confirmPassword')}
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                      }}
                    />
                    <div style={{marginTop: '-5px'}}>
                        {
                            errors.confirmPassword ?
                                <div>
                                    {
                                        touched.confirmPassword ?
                                            <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.confirmPassword}</Typography> : <></>
                                    }
                                </div> :
                                <div></div>
                        }
                    </div>
                  </Grid>
                </Grid>

                {/* Button Centered */}
                <Box display="flex" justifyContent="center" mt={3} mb={2}>
                  <Button variant="contained" onClick={handleSubmit} style={{width: '300px', borderRadius: '20px', backgroundColor:'#1E9CED'}}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                      {addBtnLoading && (
                        <CircularProgress
                          className="loader"
                          size={24}
                          style={{ marginLeft: addBtnLoading ? 8 : 0 }}
                        />
                      )}
                      <span style={{ visibility: addBtnLoading ? "hidden" : "visible" }}>Proceed</span>
                    </div>
                  </Button>
                </Box>
              </div>
            )}

          </Formik>
          </Container>
      </Box>

      {/* <---------------- Riders List -----------------> */}
      <Paper className="riderListTable" >
        <div className="d-flex riderListTableHeader">
            <Typography className="riderRecordsText" sx={{color: 'white'}}>RIDER RECORDS</Typography>
            
            <FormControl size="small" sx={{width: '12%', marginLeft: '40%' }}>
              <InputLabel id="demo-simple-select-label" color={"error"}>Filter By</InputLabel> 
              <Select
                sx={{backgroundColor: 'whitesmoke', borderRadius: '20px'}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Filter By"
                onChange={handleFilterChange}
              >
                <MenuItem value={'NAME'}>Name</MenuItem>
                <MenuItem value={'RECENT'}>Recent</MenuItem>
                <MenuItem value={'OLD'}>Old</MenuItem>
              </Select>
            </FormControl>

            <Search style={{border: '1px solid grey', marginRight: '2%', borderRadius: '20px', backgroundColor:'whitesmoke' }} >
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearch}
                />
            </Search>
        </div>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 700 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                    {
                    // loading ? (
                    //     <TableRow><CircularProgress className="tableLoader" /></TableRow>
                    // ) : (
                    //   riders?.length > 0 ? (
                      riders?.map((rider, ind) => (
                            <TableRow>
                              <TableCell>
                                {ind+1}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.organization_name} */}
                                  {highlightSearchTerm(rider.organization_name)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.name} */}
                                  {highlightSearchTerm(rider.name, searchTerm)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.mobile} */}
                                  {highlightSearchTerm(rider.mobile, searchTerm)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.email} */}
                                  {highlightSearchTerm(rider.email)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.address} */}
                                  {highlightSearchTerm(rider.address)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.pincode} */}
                                  {highlightSearchTerm(rider.pincode)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.city} */}
                                  {highlightSearchTerm(rider.city)}
                              </TableCell>
                              <TableCell>
                                  {/* {rider.state} */}
                                  {highlightSearchTerm(rider.state)}
                              </TableCell>

                              <TableCell>
                                <span className="d-flex" style={{justifyContent: 'space-evenly', fontSize: '25px', alignItems: 'center' }}>
                                  <EditIcon aria-label="edit" color="primary" style={{cursor: 'pointer', marginRight: '5px' }} onClick={() => handleOpen(rider)} /> |
                                  <DeleteIcon style={{ color: 'red', cursor: 'pointer', marginLeft: "5px" }} onClick={() => handleDeleteClick(rider.id)}/>
                                </span>
                              </TableCell>

                            </TableRow>
                        ))
                    // )
                    }
            </TableBody>
              
            <TableFooter>
              <Typography className='text-center' style={{color: 'white'}}>h</Typography>
            </TableFooter>

          </Table>
          { loading && (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                  </div>
                ) }

                { loading || riders?.length === 0 && (
                  <Typography className='text-center noRecordsFound mt-3'>No Records Found</Typography>
            ) }
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

            {/* <---------------- Update Modal ----------------->*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit} validationSchema={validationUpdateSchema} enableReinitialize={true}>
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (            
            <Box sx={style}>
                <div className="d-flex updateRiderDetailsHeader">
                  <Typography className="addRiderText">Update Rider Details</Typography>
                  <CloseIcon className="UpdateHeaderCloseIcon" sx={{}} onClick={handleClose} />
                </div>

                <FormControl sx={{ mt: 2 }}>
                  <Select
                    required
                    size="small"
                    displayEmpty
                    // value={userData?.organization_name}
                    onChange={handleUpdateSelectChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if ( !selected ) {
                        return <span style={{ color: 'gray' }}>{userData?.organization_name}</span>;
                      }
                      const selectedOrg = organization.find(org => org.id === selected);
                      console.log("selected org:", selectedOrg);
                      return selectedOrg ? selectedOrg.name : userData?.organization_name;
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {organization.map((organizationName) => (
                      <MenuItem
                        key={organizationName?.id}
                        value={organizationName?.id}
                      >
                        {organizationName?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Name"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.name}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  required
                />
                <div style={{}}>
                      {
                          errors.name ?
                              <div>
                                  {
                                      touched.name ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.name}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  label="Mobile"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  className="updateRiderformText"
                  value={values?.mobile}
                  onChange={handleChange('mobile')}
                  onBlur={handleBlur('mobile')}
                  required
                />
                <div style={{}}>
                      {
                          errors.mobile ?
                              <div>
                                  {
                                      touched.mobile ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.mobile}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  label="Email"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  required
                />
                <div style={{}}>
                      {
                          errors.email ?
                              <div>
                                  {
                                      touched.email ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.email}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  label="Address"
                  id="outlined-size-small"
                  size="small"
                  multiline
                  rows={2}
                  className="updateRiderformText"
                  value={values?.address}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  required
                />
                <div style={{}}>
                      {
                          errors.address ?
                              <div>
                                  {
                                      touched.address ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.address}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  label="Pincode"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  className="updateRiderformText"
                  value={values?.pincode}
                  onChange={handleChange('pincode')}
                  onBlur={handleBlur('pincode')}
                  required
                />
                <div style={{}}>
                      {
                          errors.pincode ?
                              <div>
                                  {
                                      touched.pincode ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.pincode}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  label="City"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.city}
                  onChange={handleChange('city')}
                  onBlur={handleBlur('city')}
                  required
                />
                <div style={{}}>
                      {
                          errors.city ?
                              <div>
                                  {
                                    touched.city ?
                                      <Typography style={{ color: 'red', fontSize: '10px' }}>{errors.city}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <TextField
                  sx={{ marginBottom: '25px' }}
                  label="State"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.state}
                  onChange={handleChange('state')}
                  onBlur={handleBlur('state')}
                  required
                />
                <div style={{}}>
                      {
                          errors.state ?
                              <div>
                                  {
                                      touched.state ?
                                          <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.state}</Typography> : <></>
                                  }
                              </div> :
                              <div></div>
                      }
                </div>

                <Button variant="contained" onClick={handleSubmit} sx={{borderRadius: '20px', backgroundColor: '#1E9CED'}}>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                      {loading2 && (
                          <CircularProgress
                              className="loader"
                              size={24}
                              style={{ marginLeft: loading2 ? 8 : 0 }}
                          />
                      )}
                      <span style={{ visibility: loading2 ? "hidden" : "visible" }}>Proceed</span>
                  </div>

                </Button>
            </Box>
          )}           
        </Formik>
      </Modal>


    </div>
  );
}

export default RiderManagement;
