import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, IconButton, Modal, CircularProgress, Grid, InputAdornment, TableFooter, Container } from "@mui/material";
import "../../saas/screens/organizationManagement.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Password, Token } from "@mui/icons-material";
import axios from "axios";
import { API } from "../../utils/Api";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Swal from "sweetalert2";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik } from "formik";
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  outline: 'none',
  borderRadius: '12px'
};


function OrganizationManagement() {

  const token = localStorage.getItem("token");
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [organization, setOrganization] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [processingFlag, setProcessingFlag] = useState(false);
  const [orgDeatils, setOrgDeatils] = useState()
  const handleOpen = (orgData) => {
    setOpen(true)
    console.log("hello orgdata: ", orgData);
    setOrgDeatils(orgData);
  };

  const [showPassword, setShowPassword] = useState(true)
  const [showConfirmPassword, setShowConfirmPassword] = useState(true)

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
    state: '',
    password: '',
    confirmPassword: '',
    // showPassword: false,
    // showConfirmPassword: false
  })

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .trim() // Remove leading and trailing whitespace
      .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Name can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
      .min(2, 'Name must be at least 2 characters long'),
    mobile: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 characters")
      .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
    email: Yup.string()
      .required("Email is required")
      .trim() // Remove leading and trailing whitespace
      .email('Enter a valid email address'), // Validate email format
    address: Yup.string()
      .required('Address is required!')
      .trim()
      .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Address can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
      .min(2, 'Address must be at least 2 characters long'),
    pincode: Yup.string()
      .required('Pincode is required')
      .trim()
      .matches(/^[0-9]+$/, 'Pincode must contain only numeric values')
      .matches(/^\d{6}$/, 'Invalid pinocde or it does not exists'),
    city: Yup.string()
      .required('City is required')
      .trim()
      .min(2, 'City must be at least 2 characters long')
      .matches(/^[A-Za-z\s-]+$/, 'City must not contain special characters'),
    state: Yup.string()
      .required('State is required!')
      .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
      .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]'),
    password: Yup.string()
      .required('New password is required')
      .trim()
      .min(10, 'New password must be at least 10 characters long'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .trim()
      .min(10, 'New password must be at least 10 characters long'),
  })

  const initialValues = {
    name: orgDeatils?.name || 'Loading...',
    mobile: orgDeatils?.mobile || 'Loading...',
    email: orgDeatils?.email || 'Loading...',
    address: orgDeatils?.address || 'Loading...',
    pincode: orgDeatils?.pincode || 'Loading...',
    city: orgDeatils?.city || 'Loading...',
    state: orgDeatils?.state || 'Loading...'
  }

  const updateValidationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .trim() // Remove leading and trailing whitespace
      .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Name can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
      .min(2, 'Name must be at least 2 characters long'),
    mobile: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 characters")
      .matches(/^[6-9][0-9]{9}$/, 'Enter a valid Phone number starting with 6, 7, 8, or 9'),
    email: Yup.string()
      .required("Email is required")
      .trim() // Remove leading and trailing whitespace
      .email('Enter a valid email address'), // Validate email format
    address: Yup.string()
      .required('Address is required!')
      .trim()
      .matches(/^[a-zA-Z0-9\s\/\\,.-]+$/, 'Address can only contain letters, numbers, spaces, forward slashes, backslashes, commas, and full stops')
      .min(2, 'Address must be at least 2 characters long'),
    pincode: Yup.string()
      .required('Pincode is required')
      .trim()
      .matches(/^[0-9]+$/, 'Pincode must contain only numeric values')
      .matches(/^\d{6}$/, 'Invalid pinocde or it does not exists'),
    city: Yup.string()
      .required('City is required')
      .trim()
      .min(2, 'City must be at least 2 characters long')
      .matches(/^[A-Za-z\s-]+$/, 'City must not contain special characters'),
    state: Yup.string()
      .required('State is required!')
      .matches(/^[A-Za-z\s'-]+$/, 'State can only contain letters, spaces, hyphens (-), or apostrophes (\')')
      .matches(/^[A-Za-z]/, 'First character can only consist of characters [a-z] or [A-Z]'),
  })


  function createData(name, code, population, size, actions) {
    const density = population / size;
    return { name, code, population, size, density, actions };
  }

  const columns = [
    { id: "sr.no", label: "Sr.No" },
    { id: "name", label: "Name", minWidth: 140 },
    { id: "email", label: "Email", minWidth: 100 },
    {
      id: "mobile",
      label: "Mobile",
      minWidth: 120,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "address",
      label: "Address",
      minWidth: 150,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "pincode",
      label: "Pincode",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "city",
      label: "City",
      minWidth: 100,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "state",
      label: "State",
      minWidth: 100,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Actions",
      minWidth: 100,
      align: "left",
    }

  ];  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getOrganizationData = async () => {
    try {
      const config = {
        headers : {
          Authorization : `${token}`
        }
      };
      setProcessingFlag(true)
      const response = await axios.get(`${API.BASE_URL}organization/list`, config);
      if(response.data.status === true) {
        console.log("organization Data:", response.data.data.list);
        setProcessingFlag(false);
        setOrganization(response.data.data.list);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        }) 
      }
    }catch (error) {
      let errorMessage = "An error occurred while fetching client's products.";
            if (error.response && error.response.data) {
              errorMessage = error.response.data.message;
            }
            setProcessingFlag(false);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
          });
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name] : value
    });
  }

  const handleSubmitOrg = async (event) => {
    console.log("hello",event);
    // event.preventDefault();
    setLoading(true);
    let orgData = {
      name: event.name,
      mobile: event.mobile,
      email: event.email,
      address: event.address,
      pincode: event.pincode,
      city: event.city,
      state: event.city,
      password: event.password,
      confirmPassword: event.confirmPassword,
    }
    console.log(orgData, "ordg orgData");
    try {
      const response = await axios.post(`${API.BASE_URL}organization/add`, orgData, {
        headers: {
          Authorization: `${token}`,
      }
      });
      console.log('new organization', response.data);
      setFormData({
        name: '',
        mobile: '',
        email: '',
        address: '',
        pincode: '',
        city: '',
        state: '',
        password: '',
        confirmPassword: '',
      });
      getOrganizationData();
      setLoading(false);
      toast.success("New Organization Created Successfully!");
    } catch (error) {
      console.log("Error Adding new organization",error);
      setLoading(false);
      toast.error("Failed To Create New Organization");
    }
  }

  const handleUpdateSubmit = async (values) => {
    // event.preventDefault();
    setLoading2(true);
    console.log("values:", values);
    console.log(orgDeatils,"org details dftrht");
    let updateData = {
      organization: orgDeatils.id,
      name: values.name,
      mobile: values.mobile,
      email: values.email,
      address: values.address,
      state: values.state,
      city: values.city,
      pincode: values.pincode
    }
    console.log(updateData,"updated data");

    try {
      const response = await axios.put(`${API.BASE_URL}organization/update`, updateData, { 
        headers: {
          Authorization: `${token}`,
      },}
    );

    console.log('new organization: ', response.data);
    getOrganizationData();
    handleClose();
    setLoading2(false);
    toast.success("Organization Updated Successfully!");

    } catch (error) {
      console.log("Error Adding new organization",error);
      setLoading2(false);
      toast.error("Failed To Update Organization");
    }
  }

  const handleDeleteClick = async (userId) => {
    console.log("userId", userId);
  }

  const togglePasswordVisibility = () => {
    console.log("form data pass:", formData);
    // setFormData({
    //     ...formData,
    //     showPassword: !formData.showPassword
    // });
    setShowPassword(!showPassword)
  };

  const toggleConfirmPasswordVisibility = () => {
      // setFormData({
      //     ...formData,
      //     showConfirmPassword: !formData.showConfirmPassword
      // });
      setShowConfirmPassword(!showConfirmPassword)
  };

  const isFormValid = formData.password !== formData.confirmPassword;

  const handleChangeTest = (dataKey, dataValue) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [dataKey]: dataValue
    }))
  }

  const generateRandomPassword = () => {
    const length = 10; // Adjust the length of the generated password as needed
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$&";

    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: password,
      confirmPassword: password
    }));
    
  };

  useEffect(() => {
    getOrganizationData();
  }, []);  


  return (
    <div className="organizationMain">

      {/* <---------------- Add Organization Form ---------------> */}
      <Box className='addOrganizationForm'>
        <div className="addOrganizationFormHeader">
          <Typography className="addOrganiZationText">ADD ORGANIZATION</Typography>
        </div>
        <Formik initialValues={formData} onSubmit={handleSubmitOrg} validationSchema={validationSchema} enableReinitialize={true}>   
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <Container style={{marginTop: '20px'}}>
              <Grid container spacing={3}>
                {/* First Row */}
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    id="outlined-size-small"
                    size="small"
                    className="addRiderformText"
                    name="name"
                    // value={formData.name}
                    value={values?.name}
                    // onChange={handleChange('name')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('name')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.name ?
                                            <div>
                                                {
                                                    touched.name ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.name}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Mobile"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    className="addRiderformText"
                    name="mobile"
                    value={values?.mobile}
                    // onChange={handleChange('mobile')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('mobile')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.mobile ?
                                            <div>
                                                {
                                                    touched.mobile ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.mobile }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>

                {/* Second Row */}
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    id="outlined-size-small"
                    size="small"
                    className="addRiderformText"
                    name="email"
                    value={values?.email}
                    // onChange={handleChange('email')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('email')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.email ?
                                            <div>
                                                {
                                                    touched.email ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.email }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    id="outlined-size-small"
                    size="small"
                    className="addRiderformText"
                    name="address"
                    multiline
                    rows={2}
                    value={values?.address}
                    // onChange={handleChange('address')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('address')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.address ?
                                            <div>
                                                {
                                                    touched.address ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.address }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>

                {/* Third Row */}
                <Grid item xs={4}>
                  <TextField
                    label="Pincode"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    className="addRiderformText"
                    name="pincode"
                    value={values?.pincode}
                    // onChange={handleChange('pincode')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('pincode')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.pincode ?
                                            <div>
                                                {
                                                    touched.pincode ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.pincode }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="City"
                    id="outlined-size-small"
                    size="small"
                    className="addRiderformText"
                    name="city"
                    value={values?.city}
                    // onChange={handleChange('city')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('city')}
                    required
                    fullWidth // Added to make the TextField take full width
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.city ?
                                            <div>
                                                {
                                                    touched.city ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.city }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>

                {/* Fourth Row */}
                <Grid item xs={4}>
                  <TextField
                    label="State"
                    id="outlined-size-small"
                    size="small"
                    className="addRiderformText"
                    name="state"
                    value={values?.state}
                    // onChange={handleChange('state')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('state')}
                    fullWidth // Added to make the TextField take full width
                    required
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.state ?
                                            <div>
                                                {
                                                    touched.state ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.state }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>
                <Grid item xs={6} sx={{paddingTop: '8px !important'}}>
                  <Typography onClick={generateRandomPassword} sx={{ fontSize: '10px', marginBottom: '2px', cursor: 'pointer', textDecoration: 'underline', color: 'blue', textAlign: 'right' }}>Generate password</Typography>
                  <TextField
                    id="outlined-password-input"
                    label="Password"
                    // type="password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    className="addRiderformText"
                    name="password"
                    value={values?.password}
                    // onChange={handleChange('password')}
                    onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                    onBlur={handleBlur('password')}
                    required
                    fullWidth // Added to make the TextField take full width
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              <IconButton onClick={togglePasswordVisibility} edge="end">
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                          </InputAdornment>
                      )
                    }}
                  />
                  <div style={{marginTop: '-10px'}}>
                                    {
                                        errors.password ?
                                            <div>
                                                {
                                                    touched.password ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{ errors.password }</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                  </div>
                </Grid>
                <Grid item xs={6}>
                        <TextField
                          id="outlined-password-input"
                          label="Confirm Password"
                          // type="password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          size="small"
                          className="addRiderformText"
                          name="confirmPassword"
                          value={values?.confirmPassword}
                          // onChange={handleChange('confirmPassword')}
                          onChange={(event) => handleChangeTest(event.target.name, event.target.value)}
                          onBlur={handleBlur('confirmPassword')}
                          required
                          fullWidth
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                          }}
                        />
                        <div style={{}}>
                            {
                                errors.confirmPassword ?
                                    <div>
                                        {
                                            touched.confirmPassword ?
                                                <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.confirmPassword}</Typography> : <></>
                                        }
                                    </div> :
                                    <div></div>
                            }
                        </div>
                </Grid>
              </Grid>
              {/* Button Centered */}
              <Box display="flex" justifyContent="center" mt={2} mb={2}>
                <Button variant="contained" onClick={handleSubmit} style={{ width: '300px', backgroundColor: '#1E9CED', borderRadius: '20px' }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                    {loading && (
                      <CircularProgress
                        className="loader"
                        size={24}
                        style={{ marginLeft: loading ? 8 : 0 }}
                      />
                    )}
                    <span style={{ visibility: loading ? "hidden" : "visible" }}>Proceed</span>
                  </div>
                </Button>
              </Box>
            </Container>
          )}
        </Formik> 
      </Box>

      {/* <-------------- List Of Organization --------------> */}
      <Paper className="organizationListTable" >
        <div className="organizationListHeader">
          <Typography className="text-left organizationText">Organization Records</Typography>
        </div>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 700 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              
                { 
                // processingFlag ? (
                //   <CircularProgress className="tableLoader" />
                // ) : (
                  organization.map((organization, ind) => (
                    <TableRow>
                      <TableCell>{ind+1}</TableCell>
                      <TableCell>{organization.name}</TableCell>
                      <TableCell>{organization.email}</TableCell>
                      <TableCell>{organization.mobile}</TableCell>
                      <TableCell>{organization.address}</TableCell>
                      <TableCell>{organization.pincode}</TableCell>
                      <TableCell>{organization.city}</TableCell>
                      <TableCell>{organization.state}</TableCell>
                      <TableCell>
                        <span className="d-flex" style={{fontSize: '25px', alignItems: 'center'}}>
                          <EditIcon aria-label="edit" color="primary" style={{ cursor: 'pointer', marginRight: '5px' }} onClick={ () => handleOpen(organization)} />
                          {/* <DeleteOutlinedIcon style={{ color: 'red', cursor: 'pointer', marginLeft: '5px' }} onClick={ () => handleDeleteClick(organization.id)} /> */}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                // )
                }
            </TableBody>
            
            <TableFooter>
                  <Typography className='text-center' style={{color: 'white'}}>h</Typography>
            </TableFooter>
          </Table>
          { processingFlag && (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                  </div>
                ) }

                { processingFlag || organization?.length === 0 && (
                  <Typography className='text-center noRecordsFound'>No Records Found</Typography>
            ) }
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

      {/* <------------ Update Modal ------------->*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit} validationSchema={updateValidationSchema} enableReinitialize={true}>
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <Box sx={style}>
                
                <div className="d-flex updateOrgModalHeader">
                  <Typography className="addRiderText">Update Organization Details</Typography>
                  <CloseIcon className="updateOrgModalCloseIcon" onClick={handleClose} />
                </div>
                <TextField
                  label="Name"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText1"
                  // value={orgDeatils?.name}
                  // onChange={(e) => setOrgDeatils({...orgDeatils, name: e.target.value})}
                  value={values?.name}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.name ?
                                            <div>
                                                {
                                                    touched.name ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.name}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="Mobile"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  className="updateRiderformText"
                  value={values?.mobile}
                  onChange={handleChange('mobile')}
                  onBlur={handleBlur('mobile')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.mobile ?
                                            <div>
                                                {
                                                    touched.mobile ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.mobile}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="Email"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.email ?
                                            <div>
                                                {
                                                    touched.email ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.email}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="Address"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  multiline
                  rows={2}
                  value={values?.address}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.address ?
                                            <div>
                                                {
                                                    touched.address ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.address}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="Pincode"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  className="updateRiderformText"
                  value={values?.pincode}
                  onChange={handleChange('pincode')}
                  onBlur={handleBlur('pincode')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.pincode ?
                                            <div>
                                                {
                                                    touched.pincode ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.pincode}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="City"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.city}
                  onChange={handleChange('city')}
                  onBlur={handleBlur('city')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.city ?
                                            <div>
                                                {
                                                    touched.city ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.city}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <TextField
                  label="State"
                  id="outlined-size-small"
                  size="small"
                  className="updateRiderformText"
                  value={values?.state}
                  onChange={handleChange('state')}
                  onBlur={handleBlur('state')}
                  required
                />
                <div style={{}}>
                                    {
                                        errors.state ?
                                            <div>
                                                {
                                                    touched.state ?
                                                        <Typography style={{ color: 'red', fontSize: '10px', }}>{errors.state}</Typography> : <></>
                                                }
                                            </div> :
                                            <div></div>
                                    }
                </div>

                <Button variant="contained" onClick={handleSubmit} sx={{marginTop: '20px', backgroundColor: '#1E9CED', borderRadius: '20px' }}>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                      {loading2 && (
                          <CircularProgress
                              className="loader"
                              size={24}
                              style={{ marginLeft: loading2 ? 8 : 0 }}
                          />
                      )}
                      <span style={{ visibility: loading2 ? "hidden" : "visible" }}>Proceed</span>
                  </div>
                
                </Button>
            </Box>
          )}
        </Formik>
      </Modal>

    </div>
  );
}

export default OrganizationManagement;
