import React, { useState, useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import Images from "../../utils/Images";
import LoginTextInput from "../../components/LoginTextInput";
import PrimaryButton from "../../components/PrimaryButton";
import { AccountCircle, AccountCircleOutlined, BorderColor, Email, EmailOutlined, Lock, Phone, PhoneAndroid } from '@mui/icons-material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../utils/Api";
import { toast } from "react-toastify";
import '../../saas/screens/signup.scss';
import { motion } from 'framer-motion';

const LogIn = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationSchema = yup.object().shape({
        email: yup.string().email("Invalid email").required("Email is required"),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters")
            .max(12, "Password must be at most 12 characters"),
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const requestBody = {
                username: data.email,
                password: data.password,
            };
            const response = await axios.post(`${API.BASE_URL}auth/admin-signin`, requestBody);
            if (response.data.status === true) {
                navigate("/ridermanagement");
                localStorage.setItem("token", response.data.data.access_token);
                localStorage.setItem("clientEmail", response.data.data.user.email);
                toast.success("Login successful!", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                console.log(response.data.message);
                toast.error("Login failed: " + response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        } catch (error) {
            toast.error(error.response.data.data.message, {
                position: "top-right",
                autoClose: 3000
            })

            let errorMessage = "An error occurred while logging in.";
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
            });
        }
        setIsSubmitting(false);
    };

    return (
        <>
            <section className="signup-page">
                <motion.div
                    // initial={{ opacity: 0, y: -150 }}
                    // animate={{ opacity: 1, y: 0 }}
                    // transition={{ duration: 0.7 }}
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.3,
                        ease: [0, 0.71, 0.2, 1.01],
                        scale: {
                            type: "spring",
                            damping: 5,
                            stiffness: 80,
                            restDelta: 0.001
                        }
                    }}

                >   
                <Container className="signBox">
                    <Grid container className="signup-logos">
                        <Grid item lg={6} >
                            <motion.div
                                initial={{ opacity: 0, x: -150 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                {/* <img src={Images.stockLogo} className="img-fluid" alt="img" /> */}
                                <Typography className="riderText">ZUPZAP ADMIN PANEL</Typography>
                            </motion.div>
                        </Grid>
                        <Grid item lg={6}>
                            {/* <img src={Images.clientPanel} className="img-fluid clientPanel text-end" alt="img" /> */}
                            {/* <Typography className="clientPanel">Admin Panel</Typography> */}
                        </Grid>
                    </Grid>
                    <Grid container className="signup">
                        <Grid item md={7} >
                            <img className="signImage img-fluid" src={Images.signImage} alt="img" />
                        </Grid>
                        <Grid item md={5} sx={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center' }} className="signupForm">
                            <h1 className="text-center">Log In</h1>
                            {/* <p className="text-center" style={{ paddingBottom: '15px' }}>Login with OTP instead? <span><Link to="/otpLogin"> Generate OTP</Link></span> </p> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <LoginTextInput
                                            {...field}
                                            placeholder="Email"
                                            startIcon={<EmailOutlined />}
                                            style={{ marginBottom: "16px" }}
                                        />
                                    )}
                                />
                                <p className="error-message">{errors.email?.message}</p>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <LoginTextInput
                                            {...field}
                                            placeholder="Password"
                                            password={true}
                                            startIcon={<Lock />}
                                            showPassword={showPassword}
                                            togglePasswordVisibility={togglePasswordVisibility}
                                            style={{ marginBottom: "16px" }}
                                        />
                                    )}
                                />
                                <p className="error-message">{errors.password?.message}</p>
                                <PrimaryButton
                                    title="Proceed"
                                    type="submit"
                                    disabled={isSubmitting}
                                    disabledTitle="Proceeding"
                                    spinner={true}
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        backgroundColor: '#1E9CED',
                                        border: '1px solid #1E9CED',
                                        borderRadius: '20px',
                                        color: 'white',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </form>
                            {/* <p className="text-center" style={{ position: 'relative', top: "15px" }}>Don't have an account? <span><Link to="/signup">Register Now</Link></span> </p> */}
                        </Grid>
                    </Grid>
                </Container>
                </motion.div>
            </section>
        </>
    );
};

export default LogIn;